<template> 
    <ValidationObserver ref="observer">
        <b-form @submit.prevent="onSubmit">

            <b-row class="display-flex align-items-end">
                <b-col md="6" xl="6">
                    <app-row-left-label>
                        <app-input v-model="cClientName" validatorName="Organisatienaam" :label="trans('organization_name',169)" validatorRules="required" mask="lettersE9-40"/>
                    </app-row-left-label>
                    <app-row-left-label>
                        <app-input v-model="cKvk.kvk_number" validatorName="KVK nummer" :label="trans('enter-kvk',182)" type="integer" validatorRules="required|digits:8" mask="########" v-on:blur="checkKvk()" :currentError="status >= 2" />
                        <app-input type="hidden" v-show="cStatus" v-model="cStatus" validatorRules="required" />
                    </app-row-left-label>
                </b-col>
                <b-col md="6" xl="6">
                    <app-row-left-label v-if="loading || status == 1" >            
                        <b-card no-body class="p-2 app-local-card-min-height app-card-info">
                            <div class="app-info-card-label px-3" v-if="loading">
                                <b-spinner class="app-local-spinner"/>{{trans('checking',180)}}
                            </div>
                            <div class="app-info-card-label px-3 py-2" v-if="!loading && status === 1">
                                <label class="flabel">{{trans('kvk-data',182)}}</label>
                                <div> {{ checked_kvk_trade_name }} </div>
                                <div> {{ checked_kvk_info }} </div>
                            </div>
                        </b-card>
                    </app-row-left-label>

                    <app-row-left-label v-if="!loading && status == 2" >            
                        <b-card no-body class="p-2 app-local-card-min-height app-card-warning">
                            <div class="app-info-card-label">
                                <label class="flabel px-3 py-2">{{trans('wrong-number',180)}}</label>
                            </div>
                        </b-card>
                    </app-row-left-label>
                    <app-row-left-label v-if="!loading && status == 3" >            
                        <b-card no-body  class="p-2 app-local-card-min-height app-card-warning" v-if="(clients_ids != null || persons_ids != null)">
                            <div class="app-info-card-label my-auto p-3">
                                <span class="app-popover-text" v-html="trans('kvk-already-used-error-p1',180)"></span>
                                <span>&nbsp;</span>
                                <span class="app-popover-text" v-if="clients_ids != null" v-html="trans('kvk-already-used-error-p2',180, {clients_names :clients_names, clients_ids : clients_ids})"></span>
                                <span v-if="clients_ids != null && persons_ids != null">&nbsp;</span>
                                <span class="app-popover-text" v-if="clients_ids != null && persons_ids != null" v-html="trans('and-word',182)"></span>
                                <span v-if="clients_ids != null && persons_ids != null">&nbsp;</span>
                                <span class="app-popover-text" v-if="persons_ids != null" v-html="trans('kvk-already-used-error-p3',180, {persons_names : persons_names, persons_ids : persons_ids})"></span>
                            </div>
                        </b-card>
                    </app-row-left-label>
                </b-col>
            </b-row>
            <!-- additionalInfo="Bestaand uit een 8-cijferig nummer "-->
        </b-form>
    </ValidationObserver>
</template>

<script>
import axios from "@axios";
import {ValidationObserver} from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: {
        kvk: {},
        clientName:{},
        required: { type: Boolean, default: false }
    },

    created() {
        
        if(this.kvk.kvk_number != null && this.kvk.kvk_number.toString().length == 8 &&
           this.kvk.kvk_data.city != null && this.kvk.kvk_data.city.toString().length > 0 && 
           this.kvk.kvk_data.street != null && this.kvk.kvk_data.street.toString().length > 0 &&
           this.kvk.kvk_data.trade_name != null && this.kvk.kvk_data.trade_name.toString().length > 0){
            this.checked_kvk_info = this.kvk.kvk_data.street + ", " + this.kvk.kvk_data.city;
            this.checked_kvk_trade_name = this.kvk.kvk_data.trade_name;
            this.status = 1;
            this.basic_kvk = JSON.parse(JSON.stringify(this.cKvk));
        }

        if(this.kvk.kvk_number != null && this.kvk.kvk_number.length != 0 && this.kvk.kvk_data.city == "" && this.kvk.kvk_data.street == "" && this.kvk.kvk_data.trade_name == ""){
            this.status = 2;
        }

    },

    mounted(){
        if(this.kvk.kvk_number != null && this.kvk.kvk_number.length != 0 && this.kvk.kvk_data.city == null && this.kvk.kvk_data.street == null && this.kvk.kvk_data.trade_name == null){
            this.checkKvk();
        }
    },

    computed: {
        cClientName:{
            get() { return this.clientName },
            set(value) { this.$emit('update:clientName', value) }
        },
        cKvk:{
            get() { return this.kvk },
            set(value) { this.$emit('update:kvk', value) }
        },
        cStatus: {
            get(){
                return this.status == 1 ? true : null;
            }
        }
    },
    
    data() {
        return {
            loading: false,
            status: 0,   
            checked_kvk_info: "",
            checked_kvk_trade_name: "",
            clients_ids:null,
            clients_names: null,
            persons_ids: null,
            persons_names: null,
            basic_kvk: null,
        };
    },

    methods: {
        checkKvk(){
            if(this.basic_kvk && this.basic_kvk.kvk_number == this.kvk.kvk_number)  {
                this.cKvk.kvk_number = this.basic_kvk.kvk_number;
                this.cKvk.kvk_data.kvk_number = this.basic_kvk.kvk_number;
                this.cKvk.kvk_data.city = this.basic_kvk.kvk_data.city;
                this.cKvk.kvk_data.street = this.basic_kvk.kvk_data.street;
                this.cKvk.kvk_data.trade_name = this.basic_kvk.kvk_data.trade_name;
                this.clients_ids = null
                this.clients_names = null
                this.checked_kvk_info = this.basic_kvk.kvk_data.street + ", " + this.basic_kvk.kvk_data.city;
                this.checked_kvk_trade_name = this.basic_kvk.kvk_data.trade_name;
                this.status = 1;
                return;
            }

            if(this.kvk.kvk_number === null || this.kvk.kvk_number === undefined || this.kvk.kvk_number.toString().length !== 8){
                this.status = 0;
                this.checked_kvk_info = "";
                this.checked_kvk_trade_name = "";
                this.cKvk.kvk_data.kvk_number = "";
                this.cKvk.kvk_data.city = "";
                this.cKvk.kvk_data.street = "";
                this.cKvk.kvk_data.trade_name = "";
                return;
            }

            this.loading = true;
            this.cKvk.kvk_data.kvk_number = "";
            this.cKvk.kvk_data.city = "";
            this.cKvk.kvk_data.street = "";
            this.cKvk.kvk_data.trade_name = "";

            this.$refs.observer.validate();
            
            axios
                .get("core/kvk/check/" + this.kvk.kvk_number)     
                .then((response) => {
                    
                    if(response.data == -1){
                        this.checked_kvk_info = "";
                        this.checked_kvk_trade_name = "";
                        return this.status = 2;
                    }

                    this.cKvk.kvk_number = response.data.kvk_number;
                    this.cKvk.kvk_data.kvk_number = response.data.kvk_number;
                    this.cKvk.kvk_data.city = response.data.city;
                    this.cKvk.kvk_data.street = response.data.street;
                    this.cKvk.kvk_data.trade_name = response.data.trade_name;
                    this.clients_ids = response.data.clients_ids; 
                    this.clients_names = response.data.clients_names; 
                    this.persons_ids = response.data.persons_ids;
                    this.persons_names = response.data.persons_names;
                    this.checked_kvk_info = this.kvk.kvk_data.street + ", " + this.kvk.kvk_data.city;
                    this.checked_kvk_trade_name = this.kvk.kvk_data.trade_name;

                    if(this.clients_ids || this.persons_ids){
                        this.cKvk.kvk_data.kvk_number = "";
                        this.cKvk.kvk_data.city = "";
                        this.cKvk.kvk_data.street = "";
                        this.cKvk.kvk_data.trade_name = "";
                        this.checked_kvk_info = "";
                        this.checked_kvk_trade_name = "";
                        return this.status = 3;
                    }
                    this.status = 1;
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(`error during checking KVK: ${error}`);
                })
                .finally(() =>{
                    this.loading = false;
                });

        }


    },
};
</script>

<style scoped>
.app-local-card-min-height {
    min-height: 96px;
}

.app-local-spinner {
    width:16px; 
    height:16px; 
    margin-right:10px;
}

.kvk-data-inf-large{
    font-size:14px
}

.kvk-data-inf-small{
    font-size:12px
}

.app-local-justify{
    text-align:justify;
}
</style>
