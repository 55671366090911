<template>
  <div>

    <b-row> 
        <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">

        <b-skeleton-wrapper :loading="show_skeleton">
            <template #loading>
                <b-card class="mb-3">
                    <b-skeleton width="20%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton width="30%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton height="40px"></b-skeleton>
                </b-card>

                <b-card class="mb-3">
                    <b-skeleton width="20%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton width="40%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton height="40px"></b-skeleton>
                </b-card>

                <b-card class="mb-3">
                    <b-skeleton width="20%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton width="75%" height="40px"></b-skeleton>
                </b-card>

                <b-card class="mb-3">
                    <b-skeleton width="20%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton width="60%" height="40px" class="mb-2"></b-skeleton>
                    <b-skeleton width="50%" height="50px"></b-skeleton>
                </b-card>

            </template>

            <div>

                <div class="mt-3 mb-1 d-flex align-items-center" v-if="step > 0">
                    <b-link v-if="step > 1" href="#" @click="goBack" class="d-flex align-items-center"><b-icon icon="arrow-left" font-scale="1" class="mr-2"></b-icon>{{trans('previous-step',175)}}</b-link><span v-if="step > 1" class="mr-2 ml-2 text-muted"> | </span> 
                    <b-link href="#" @click="goProspectCard">{{trans('back-to-prospect',189)}}</b-link>
                </div>

                <ValidationObserver ref="form">
                    <form @submit.prevent="onSubmit">
                        <content-step-1 :itemData.sync="item_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading" v-if="step === 1"/>
                        <content-step-2 :itemData.sync="item_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading" :refreshing="refreshing" v-else-if="step === 2"/>
                        <content-step-3 :itemData.sync="item_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading" v-else-if="step === 3" />
                    </form>
                </ValidationObserver>

                <div v-if="step !== 5" class="mt-4 mb-3" >
                    <app-button @click="saveStep(0)" type="cancel" :disabled="loading == 1"> {{trans('save',175)}} </app-button>
                    <app-button type="primary" @click="nextStep()" :disabled="loading == true" :loading="loading">
                        {{trans('forward',175)}}
                    </app-button>
                </div>

            </div>            
            

        </b-skeleton-wrapper>

        </b-col>
    </b-row> 
    
  </div>
</template>

<script>

import axios from "@axios";
import ContentStep1 from "./Step1/ClientsProspectWizardStep1.vue";
import ContentStep2 from "./Step2/ClientsProspectWizardStep2.vue"
import ContentStep3 from "./Step3/ClientsProspectWizardStep3.vue";
import { ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
    components: { 
       ContentStep1,
       ContentStep2,
       ContentStep3,
       ValidationObserver
    },

    created() {
        this.getData();
    },

    data() {
        return {
            step: 1,
            id_client_data_main: this.$route.params.id_client_data_main,
            item_data: null,
            show_skeleton: true,
            loading: false,
            refreshing: false,
            loading_next_step: false,
            loading_partial: false,
            show_success_alert: false,
            show_unsuccess_alert: false

        };
    },

    watch: {
      "item_data.refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.refreshing = true;
                    this.getData();
                }                
            },
        },
    },

    methods: {
        getData: function(){
            this.loading = true;
            var filters = null;
            if (this.item_data != undefined && this.item_data != null && this.item_data.locations != undefined && this.item_data.locations != null) {
                filters = this.item_data.locations.filters;
            } 
            
            axios
                .post("clients/profile/web-form/getData", {
                    id_client_data_main: this.id_client_data_main,
                    step: this.step,
                    filters: JSON.stringify(filters),
                })
                .then((res) => { 
                    this.item_data = res.data;
                    this.loading = false;
                    this.refreshing = false;
                    this.show_skeleton = false;
                    if(this.step ==3){
                        this.item_data.question_2 = JSON.parse(this.item_data.question_2);
                        this.item_data.question_3 = JSON.parse(this.item_data.question_3);
                    }
                })
                .catch((error) => {
                   console.error(`error during request: ${error}`);
                   this.loading = false;
                   this.show_skeleton = false;
                   this.refreshing = false;
                });          

            

        },

        saveStep(go_forward){
            
            if(go_forward == 1){

                if(this.step === 1){// krok pierwszy sprawdzanie poprawnosci adresu i KVK

                    if((this.item_data.address.city === null || this.item_data.address.city == "") && (this.item_data.address.street === null || this.item_data.address.street == "")){
                        return false;
                    }

                    if(this.item_data.kvk.kvk_number === null || this.item_data.kvk.kvk_number.toString().length !== 8 || 
                      this.item_data.kvk.kvk_data.city === null || this.item_data.kvk.kvk_data.city.toString().length === 0 ||
                      this.item_data.kvk.kvk_data.street === null || this.item_data.kvk.kvk_data.street.toString().length === 0 ||
                      this.item_data.kvk.kvk_data.trade_name === null || this.item_data.kvk.kvk_data.trade_name.toString().length === 0){
                        return false;
                    }
                    
                    this.item_data.client_data.invoice_error = 0;
                    
                    if(this.item_data.client_data.cost_place != 1 && this.item_data.client_data.flexworker != 1  && this.item_data.client_data.week != 1){
                        this.item_data.client_data.invoice_error = 1;
                        return false;
                    }

                }
                
                if(this.step === 2) {
                    
                    if(this.item_data.organization_scale.confirm_scale == 0 || this.item_data.organization_scale.confirm_scale == null){
                        this.item_data.organization_scale.confirm_scale = null;
                        return false;
                    }
                    
                    this.item_data.client_data.cao_error = 0;
                    if(this.item_data.client_data.id_cao_data_main < 0 || this.item_data.client_data.id_cao_data_main == null){
                        this.item_data.client_data.cao_error = 1;
                        return false;
                    }

                }
                
                if(this.step === 3){
                    if(this.item_data.confirm_checklist == 0 || this.item_data.confirm_checklist == null){
                        this.item_data.confirm_checklist = null;
                        return false;
                    }
                }
                
                

                this.loading_next_step = true;
            }

            this.loading = true;

            let form_data = new FormData();

            if(this.step == 1){//zapisanie kroku 1 
                
                form_data.append("item_data", JSON.stringify(this.item_data));
                form_data.append("go_forward", go_forward);
                form_data.append("id_client_data_main", this.id_client_data_main);
                
                axios
                .post("clients/profile/web-form/saveStepOne",  form_data)
                .then((res) => {
                    
                    if(res.data != 1){
                        this.item_data = res.data;
                        this.step = 2;
                    }

                    this.loading_next_step = false;
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loading_next_step = false;
                    this.show_unsuccess_alert = !this.show_unsuccess_alert;
                })
                .finally(() => {
                    this.loading = false;
                    this.loading_next_step = false;
                    
                    if(go_forward == 0){
                        this.show_success_alert = !this.show_success_alert;
                    }

                    document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
                    
                });

            } else if(this.step == 2){//zapisanie kroku 2 
               
                form_data.append("item_data", JSON.stringify(this.item_data));
                form_data.append("go_forward", go_forward);
                form_data.append("id_client_data_main", this.id_client_data_main);

                axios
                .post("clients/profile/web-form/saveStepTwo",  form_data)
                .then((res) => {
                    
                    if(res.data != 1){
                        this.item_data = res.data;
                        this.item_data.question_2 = JSON.parse(this.item_data.question_2);
                        this.item_data.question_3 = JSON.parse(this.item_data.question_3);
                        this.step = 3;
                    }

                    this.loading_next_step = false;
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loading_next_step = false;
                    this.show_unsuccess_alert = !this.show_unsuccess_alert;
                })
                .finally(() => {
                    this.loading = false;
                    this.loading_next_step = false;

                    if(go_forward == 0){
                        this.show_success_alert = !this.show_success_alert;
                    }

                    document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });

                });

            } else if(this.step == 3){
                form_data.append("item_data", JSON.stringify(this.item_data));
                form_data.append("go_forward", go_forward);
                form_data.append("id_client_data_main", this.id_client_data_main);

                axios
                .post("clients/profile/web-form/saveStepThree",  form_data)
                .then((res) => {
                    
                    if(res.data == 2){
                        this.goProspectCard();
                    }
                    
                    this.loading_next_step = false;
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loading_next_step = false;
                    this.show_unsuccess_alert = !this.show_unsuccess_alert;
                })
                .finally(() => {
                    this.loading = false;
                    this.loading_next_step = false;

                    if(go_forward == 0){
                        this.show_success_alert = !this.show_success_alert;
                    }

                    document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });

                });
            }
            
        },

        nextStep() {
            
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    setTimeout(() => {
                        const errors = Object.entries(this.$refs.form.refs)
                            .map(([key, value]) => ({
                                key,
                                value,
                            }))
                            .filter((error) => {
                                if (!error || !error.value || !error.value.failedRules) return false;
                                return Object.keys(error.value.failedRules).length > 0;
                            });
                        if (errors && errors.length > 0) {
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                    }, 100);

                    return false;
                }
                
                this.saveStep(1);
            });


        },

        goBack: function() {
            
            this.loading = true;
            var filters = null;
            if (this.item_data != undefined && this.item_data != null && this.item_data.locations != undefined && this.item_data.locations != null) {
                filters = this.item_data.locations.filter;
            } 

            axios
                .post("clients/profile/web-form/getData", {
                    id_client_data_main: this.id_client_data_main,
                    step: (this.step - 1),
                    filters: JSON.stringify(filters),
                })
                .then((res) => {  
                    this.item_data = res.data;
                    this.step = this.step - 1;
                    this.loading = false;
                })
                .catch((error) => {
                   console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;

                    document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });

                });          
        },

        goProspectCard: function(){
            this.$router.push({ name: "clients-profile-organization", params: { id_client_data_main: this.id_client_data_main } });
        }
    },
};
</script>

<style>
    /*.app-webform-light-button {
        color: rgba(0, 0, 0, 0.5);
    }

    .app-webform-light-button:hover,  .app-webform-light-button:focus {
        color: rgba(0, 0, 0, 0.7);
    }

    .bg-light{
        background-color: white !important;
    }*/
</style>
