<template> 
    <ValidationObserver ref="observer">
        <b-row class="display-flex align-items-end">
            <b-col md="6">
                <app-row-left-label >
                    <app-input id="vi-post-code" v-model="cAddress.post_code" :currentError.sync ="currennt_error" :placeholder="trans('postcode',182)" :label="trans('postcode',182)" validatorName="Postcode" validatorRules="required|min:6" mask="####AA" :disabled="loading" v-on:blur="checkAddress()" :validatorCustomMessage="{ min: trans('invalid-postcode',180) }" :uppercase="true"/>
                </app-row-left-label>
                <app-row-left-label :slots="[6,6]">
                    <template v-slot:col-1>
                        <app-input id="vi-house-number" v-model="cAddress.house_number" :currentError.sync ="currennt_error" :key="refresh" :placeholder="trans('house-number',182)" :label="trans('house-number',182)" validatorName="Huisnummer" validatorRules="required" :disabled="loading" v-on:blur="checkAddress()" type="integer_05" />
                    </template>
                    <template v-slot:col-2>
                        <app-input id="vi-number-additional" v-model="cAddress.house_number_additional"  :currentError.sync ="currennt_error_additional" :placeholder="trans('addition',182)" :label="trans('addition',182)"  :disabled="loading" v-on:blur="checkAddress()" mask="lettersE3-5"/>
                    </template>
                </app-row-left-label>
            </b-col>
            <b-col md="6">
                <app-row-left-label v-if="loading || status == 1" >            
                    <b-card no-body class="app-local-card-min-height app-card-info">
                        <div class="app-info-card-label" v-if="loading">
                            <b-spinner class="app-local-spinner"/>{{trans('checking',180)}}
                        </div>
                        <div class="app-info-card-label" v-if="!loading && status === 1">
                            <label class="flabel">{{trans('found-address',3)}}</label>
                            <div> {{ checked_street_info }} </div>
                            <div> {{ checked_city_info }} </div>
                        </div>
                    </b-card>
                </app-row-left-label>

                <app-row-left-label v-if="!loading && status == 2" >            
                    <b-card no-body class="app-local-card-min-height app-card-warning">
                        <div class="app-info-card-label">
                            <label class="flabel">{{trans('invalid-address',180)}}</label>
                        </div>
                    </b-card>
                </app-row-left-label>
            </b-col>
        </b-row>
    </ValidationObserver>
</template>

<script>
import axios from "@axios";
import {ValidationObserver} from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: {
     address: {},
     required: { type: Boolean, default: false }
    },

    created() {
        
        if (this.address.house_number_additional !== null && this.address.house_number_additional.toString().length > 0) {
            this.checked_address_key = this.address.post_code.toString() + this.address.house_number.toString() + this.address.house_number_additional.toString();
            this.checked_street_info = this.address.street.toString() + " " + this.address.house_number.toString() + " " + this.address.house_number_additional.toString();                                   
        } else {
            this.checked_address_key = this.address.post_code.toString() + this.address.house_number.toString();
            this.checked_street_info = this.address.street.toString() + " " + this.address.house_number.toString();
        }

        this.checked_city_info = this.address.post_code.toString() + " " + this.address.city.toString()

        if (this.checked_address_key != null && this.checked_address_key.length > 0) {
            this.status = 1;
        }
    },

    computed: {
        cAddress:{
            get() { return this.address },
            set(value) { this.$emit('update:address', value) }
        },

        cErrorMessage:{
            get() { 
                    if (this.loading) return "";
                    else if (this.address.post_code.length == 0 && this.address.house_number.length == 0 && this.address.house_number_additional == null) return this.trans('address-required',3)
                    else if (this.address.post_code.length == 0) return this.trans('postcode-required',180)
                    else if (this.address.post_code.length < 6) return this.trans('invalid-postcode',180)
                    else if (this.address.house_number.length == 0) return this.trans('house-number-required',180)
                    else if (this.status == 2) return this.trans('invalid-address',180)
                    return ""
                }
        },

        cAddressRequired: {
            get() { 
                if (this.loading) return null;
                else if(this.required) return "required";
                else if(this.address.post_code.length > 0 || this.address.house_number.length > 0 || (this.address.house_number_additional !== null && this.address.house_number_additional.length > 0)) return "required";
                return null;
            }
        }
    },
    
    data() {
        return {
            loading: false,   
            checked_address_key : "",
            checked_street_info: "",
            checked_city_info: "",
            currennt_error: false,
            currennt_error_additional: false,
            status: 0,
            refresh:0
        };
    },

    
    methods: {
        checkAddress() {
            this.refresh++;

            var address_key = "";
            if (this.address.house_number_additional != null) {
                address_key = this.address.post_code + this.address.house_number + this.address.house_number_additional;
            } else {
                address_key = this.address.post_code + this.address.house_number;
            }
            
            if (address_key != this.checked_address_key) {
                this.status = 0;
                this.checked_address_key = ""
                this.checked_city_info = "";
                this.checked_street_info = "";
                this.cAddress.city = "";
                this.cAddress.street = "";
                this.cAddress.latitude = null;
                this.cAddress.longitude = null;
                this.currennt_error = false;
                this.currennt_error_additional = false;
            } 

            if (address_key == this.checked_address_key) {
                this.$refs.observer.validate();
                return;
            }

            if(this.address.post_code.length < 6 || this.address.house_number.length == 0) {
                this.$refs.observer.validate();
                return;
            }

            this.loading = true;
            this.cAddress.city = "";
            this.cAddress.street = "";
            this.cAddress.latitude = null;
            this.cAddress.longitude = null;
            this.$refs.observer.validate();

            axios
                .post("core/post-nl/check", { 
                    post_code: this.address.post_code, 
                    house_number: this.address.house_number, 
                    number_additional: this.address.house_number_additional, 
                    })
                .then((res) => {
                    if(res.data == -1){
                        this.status = 2;
                        this.currennt_error = true;
                        if(this.cAddress.house_number_additional && this.cAddress.house_number_additional.length > 0){
                            this.currennt_error_additional = true;
                        }
                    }else if (res.data === "" || res.data === undefined || res.data === null) {
                        this.status = 2;
                        this.currennt_error = true;
                        if(this.cAddress.house_number_additional && this.cAddress.house_number_additional.length > 0){
                            this.currennt_error_additional = true;
                        }
                    } else {

                        if (res.data.number_additional !== null) {
                            this.checked_address_key = res.data.post_code.toString() + res.data.house_number.toString() + res.data.number_additional.toString();
                            this.checked_street_info = res.data.street.toString() + " " + res.data.house_number.toString() + " " + res.data.number_additional.toString();                                   
                        } else {
                            this.checked_address_key = res.data.post_code.toString() + res.data.house_number.toString();
                            this.checked_street_info = res.data.street.toString() + " " + res.data.house_number.toString();
                        }

                        this.checked_city_info = res.data.post_code.toString() + " " + res.data.city.toString()
                        
                        this.cAddress.city = res.data.city;
                        this.cAddress.street = res.data.street;
                        this.cAddress.latitude = res.data.latitude;
                        this.cAddress.longitude = res.data.longitude;
                        
                        this.status = 1;
                    }
   
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                }); 
        },
    },
};    
</script>

<style scoped>
.app-local-card-min-height {
    min-height: 96px;
    padding: 1rem 1.5rem;
}

.app-local-spinner {
    width:16px; 
    height:16px; 
    font-size: 11px;
    margin-right:10px;
}
</style>
