<template>
    <div>
        
        <h1 class="mt-2 mb-4">{{trans('cw-register-organization-information',193)}}</h1>

        <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
            <div>
                    <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('successfully-saved',193)}}</span>
            </div>
            
        </b-alert>

        <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
            <div>
                    <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('unsuccessfull-save',193)}}</span>
            </div>
            
        </b-alert>

        <b-overlay :show="loading" :opacity="0.0">

            <b-card class="mb-3">
                <div class="app-card-profile-title mb-3">{{trans('general-data',3)}}</div>
                <clients-prospect-wizard-organization-general-component  :kvk="cItemData.kvk" :clientName.sync="cItemData.client_data.client_name" />
            </b-card>

            <b-card class="mb-3">
                <div class="app-card-profile-title mb-3">{{trans('address-details',3)}}</div>
                <app-wizard-address-nl :address="cItemData.address"/>
            </b-card>

            <b-card class="mb-3">
                <div class="app-card-profile-title mb-3">{{trans('cw-registration-financial-data',193)}}</div>

                <app-row-left-label :slots="[6,6]" :isMarginBetween="false">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('hours-on-invoice',168)}}</label>
                        <app-select v-model="cItemData.client_data.hours_on_invoice" reduceValue :options="invoice_hours_options"  :clearable="false" />
                    </template>
                    <template v-slot:col-2>
                        <label class="app-input-top-label">{{trans('invoice-frequency',182)}}</label>
                        <app-select v-model="cItemData.client_data.id_frequency_const_type" :disable="true" reduceValue :options="frequency" :clearable="false" validatorRules="required" validatorName="Vrijgesteld van BTW" :validatorCustomMessage="{ required: trans('field-required-error',180) }"/>
                    </template>
                </app-row-left-label>

                <app-row-left-label :slots="[6, 6]" :isMarginBetween="false">
                    <template v-slot:col-1>
                         <app-email :email.sync="cItemData.client_data.invoice_email" validatorName="Factuur email adres" validatorRules="required" :label="trans('cp-invoice-email-address',192)" type="client" customSpinnerClass="app-local-input-spinner"/>
                    </template>
                    <template v-slot:col-2>
                        <label class="app-input-top-label">{{trans('split-invoice-by',182)}}</label>
                        <b-row class="m-1">
                            <app-check-box id="ch_cost_place" v-model="cItemData.client_data.cost_place" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="trans('cp-cost-center',192)" />
                            <app-check-box id="ch_flexworker" v-model="cItemData.client_data.flexworker" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="trans('flexworker',182)" />
                            <app-check-box id="ch_week" v-model="cItemData.client_data.week" class="custom-control-primary" value="1" unchecked-value="0" size="lg" rightLabel="Week" />
                        </b-row>
                        <small class="text-danger" v-if="cItemData.client_data.invoice_error == 1">{{trans('field-required-error',180)}}</small>
                    </template>
                </app-row-left-label>
                <div class="mb-3">
                    <small class="text-muted"> {{trans('cw-registration-standard-payment-term-info',193)}}</small>
                </div>
            </b-card>
            

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>

                <!--additionalInfo="HappyNurse hanteert een standaard betaaltermijn van 14 dagen."-->
                <!--additionalInfo="Landcode NL, 9 cijfers, letter B en een controle getal van 2 cijfers."-->
    </div>
</template>

<script>
import AppWizardAddressNl from '@core/components/Address/AppWizardAddressNl.vue';
import AppEmail from '@core/components/AppEmail.vue';
import ClientsProspectWizardOrganizationGeneralComponent from './Components/ClientsProspectWizardOrganizationGeneralComponent.vue';


export default {
    components: {
        AppWizardAddressNl,
        AppEmail,
        ClientsProspectWizardOrganizationGeneralComponent,
    },
    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading"],
    
    mounted(){
        //tymczasowo
        this.cItemData.client_data.id_frequency_const_type = 1;
    },

    watch:{
        showSuccessAlert(val){
            this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        },

        "itemData.client_data": {
            handler: function(val) {
                if(this.cItemData.client_data.cost_place != 1 && this.cItemData.client_data.flexworker != 1  && this.cItemData.client_data.week != 1){
                    this.cItemData.client_data.invoice_error = 1;
                }else{
                    this.cItemData.client_data.invoice_error = 0;
                }             
            },
            deep: true
        }
    },

    computed: {
        cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        }
    },

    data(){
        return{
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,

            frequency: [
                { value: 1, name: this.trans('cw-registration-weekly',193) },
                { value: 2, name: this.trans('cw-registration-every-four-weeks',193) },
                { value: 3, name: this.trans('cw-registration-monthly',193) },
            ],
            invoice_hours_options: [
                { value: 0, name: this.trans('day-part-day',182) },
                { value: 1, name: this.trans('declaration',3) },
                { value: 2, name: this.trans('week',3) }
            ],
        }
    },

    methods:{
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        }
    }
};
</script>

